@import "react-day-picker/lib/style.css";

/* General Mini Dashboard Calendar */
.DayPickerMini .DayPicker {
    @apply transition-all;
}

.DayPickerMini .DayPicker-wrapper {
    padding-bottom: 0px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
}

.DayPickerMini .DayPicker-Caption {
    @apply text-grey-850 text-[19px];
}

.DayPickerMini .DayPicker-Weekday {
    @apply font-bold text-primary text-15 gap-15;
}

.DayPickerMini .DayPicker-Day {
    height: 40px !important;
    max-height: 40px;
    min-width: 40px !important;
    font-size: 13px !important;
    position: relative;
    @apply rounded-[0px] p-0 !font-sans !font-semibold  !text-grey-850 gap-15;
}

.DayPickerMini
    .DayPicker-Day:hover:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    background: rgba(169, 144, 88, 0.1) !important;
}

.DayPickerMini .DayPicker-NavButton--prev {
    background-image: url("/assets/images/navbar-left.png");
    height: 12px;
    background-size: contain;
    @apply my-auto left-20 top-26 text-primary;
}

.DayPickerMini .DayPicker-NavButton--next {
    background-image: url("/assets/images/navbar-right.png");
    height: 12px;
    background-size: contain;
    @apply my-auto right-20 top-26 text-primary;
}

.DayPickerMini .DayPicker-Day--disabled {
    text-decoration: line-through;
    @apply !opacity-30;
}

.DayPickerMini .DayPicker-Caption {
    text-align: center;
    @apply text-grey-850 text-[19px];
}

/* Colours */
.DayPickerMini {
    --color-mini-ama: 255, 195, 109;
    --color-mini-ama-no-client: 126, 126, 126;
    --color-mini-airbnb: 255, 131, 107;
    --color-mini-other: 110, 207, 224;
    --color-mini-file: 124, 175, 108;
    --color-mini-white: 255, 255, 255;
}

/* Different Sources */
.DayPickerMini .DayPicker-Day--outside {
    --event-background: var(--color-mini-white) !important;
    --event-background-start: var(--color-mini-white) !important;
    --event-background-end: var(--color-mini-white) !important;
}

.DayPickerMini .DayPicker-Day--airbnb {
    --event-background: var(--color-mini-airbnb);
}
.DayPickerMini .DayPicker-Day--other {
    --event-background: var(--color-mini-other);
}
.DayPickerMini .DayPicker-Day--file {
    --event-background: var(--color-mini-file);
}
.DayPickerMini .DayPicker-Day--amaSelections {
    --event-background: var(--color-mini-ama);
}
.DayPickerMini .DayPicker-Day--amaSelectionsNoClient {
    --event-background: var(--color-mini-ama-no-client);
}

.DayPickerMini .DayPicker-Day--airbnb.DayPicker-Day--start{
    --event-background-start: var(--color-mini-airbnb);
}
.DayPickerMini .DayPicker-Day--airbnb.DayPicker-Day--end {
    --event-background-end: var(--color-mini-airbnb);
}

.DayPickerMini .DayPicker-Day--other.DayPicker-Day--start{
    --event-background-start: var(--color-mini-other);
}
.DayPickerMini .DayPicker-Day--other.DayPicker-Day--end {
    --event-background-end: var(--color-mini-other);
}

.DayPickerMini .DayPicker-Day--file.DayPicker-Day--start{
    --event-background-start: var(--color-mini-file);
}
.DayPickerMini .DayPicker-Day--file.DayPicker-Day--end {
    --event-background-end: var(--color-mini-file);
}

.DayPickerMini .DayPicker-Day--amaSelections.DayPicker-Day--start{
    --event-background-start: var(--color-mini-ama);
}
.DayPickerMini .DayPicker-Day--amaSelections.DayPicker-Day--end {
    --event-background-end: var(--color-mini-ama);
}

.DayPickerMini .DayPicker-Day--amaSelectionsNoClient.DayPicker-Day--start{
    --event-background-start: var(--color-mini-ama-no-client);
}
.DayPickerMini .DayPicker-Day--amaSelectionsNoClient.DayPicker-Day--end {
    --event-background-end: var(--color-mini-ama-no-client);
}

.DayPicker-Day--start .DayPicker-Day--end {
    background-image: linear-gradient(
        to left,
        rgba(var(--event-background-start), 1) 50%,
        rgba(var(--event-background-end), 1) 50%
    );
}

.DayPicker-Day--end {
    background-image: linear-gradient(
        to left,
        transparent 50%,
        rgba(var(--event-background-end), 1) 50%
    );
}

.DayPicker-Day--start {
    background-image: linear-gradient(
        to right,
        transparent 50%,
        rgba(var(--event-background-start), 1) 50%
    );
}

.DayPickerMini .DayPicker-Day:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    background-color: rgba(var(--event-background), 0.5);
}

