.react-time-picker {
  display: inline-flex;
  position: relative;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.react-time-picker__inputGroup {
  min-width: calc(0.52em * 6 + 0.217em * 2 + 40px);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  @apply ml-5;
}

.input-customer.time-picker-label .react-time-picker__inputGroup {
  @apply pt-20;
}

.input-admin.time-picker-label .react-time-picker__inputGroup {
  @apply pt-10;
}

.react-time-picker__inputGroup input,
.react-time-picker__inputGroup select {
  @apply px-0 py-0 bg-transparent focus:ring-0;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
  display: inline-block;
  font: inherit;
}

.react-time-picker__inputGroup__leadingZero {
  @apply ml-0;
}

.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.input-customer input,
.input-customer span,
.input-customer select {
  @apply text-14 lg:text-15;
}

.input-admin input,
.input-admin span,
.input-admin select {
  @apply text-16;
}

.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.76em;
  padding-left: calc(1px + 0.76em) !important;
}

.react-time-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 4px;
}

.react-time-picker__button:enabled {
  cursor: pointer;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  @apply stroke-primary-active text-primary-active;
}

.react-time-picker__button:disabled .react-time-picker__button__icon {
  @apply stroke-grey-400 text-grey-400;
}

.react-time-picker__button svg {
  display: inherit;
}

.react-time-picker__clock {
  width: 190px;
  height: 190px;
  max-width: 100vw;
  padding: 20px;
  background-color: white;
  z-index: 50;

  @apply mt-2 border border-opacity-50 border-primary-fresh rounded-16;
}

.react-time-picker__clock--closed {
  display: none;
}

.react-time-picker__clock-button {
  @apply pointer-events-none;
}