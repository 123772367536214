
.text-editor .ql-toolbar {
    @apply !bg-primary !bg-opacity-4 !border-none !flex !flex-wrap !items-center !gap-x-10 !gap-y-10;
}

.text-editor .ql-container {
    @apply !border-none !h-[350px];
}

.text-editor .ql-formats {
    @apply !mr-0
}

.text-editor .ql-toolbar button {
    @apply !bg-white !p-8 !h-40 !w-54 !flex !justify-center !items-center !border-solid !border-3 !border-primary !border-opacity-6 !rounded-6 !text-grey-850;
}

.text-editor .ql-toolbar .ql-header {
    @apply !w-[130px];
}

.text-editor .ql-active .ql-stroke {
    @apply !stroke-primary;
}

.text-editor .ql-active .ql-fill {
    @apply !fill-primary;
}


.text-editor .ql-toolbar .ql-picker {
    @apply !bg-white !p-10 !h-40 !border-solid !border-3 !border-primary !border-opacity-6 !rounded-6 !text-grey-850 !min-w-[54px];
}

.text-editor .ql-toolbar .ql-picker .ql-picker-label {
    @apply !flex !items-center !text-grey-850 
}