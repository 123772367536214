@import "react-dates/lib/css/_datepicker.css";

.DayPicker_weekHeader {
    @apply border-b-0 bg-grey-150;
}

.DayPicker_weekHeader_li {
    @apply font-medium font-poppins text-grey-650 text-14 leading-22;
}

.DayPicker_weekHeader_ul {
    height: 38px;
    @apply grid items-center grid-cols-7 uppercase justify-items-center;
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 34px;
}

.CalendarMonth_caption {
    @apply text-left;
}

.CalendarMonth strong {
    @apply font-medium uppercase font-poppins pl-15 text-12 leading-22 tracking-1/2 text-grey-650;
}

.CalendarMonthGrid {
    width: 100% !important;
}

.CalendarMonth_verticalSpacing {
    width: 100%;
}

.CalendarDay {
    position: relative;
    font-weight: bold;
    font-size: 13px;

    @apply !border-0;
}

.CalendarDay__selected {
    border-radius: 22px;
    z-index: 1;

    @apply bg-white;
}

.CalendarDay__default:hover {
    @apply bg-transparent;
}

.CalendarDay__selected_span:hover {
    @apply text-grey-800;
}

@media (hover: hover) and (pointer: fine) {
    .CalendarDay__default:hover {
        z-index: 1;
        position: relative;
        
        @apply !text-white;
    }

    .CalendarDay__default:not(.CalendarDay__selected_span):not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected):hover:after {
        content: "";
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-radius: 22px;

        @apply mx-auto bg-primary-300 aspect-1;
    }
}

.CalendarDay__firstDayOfWeek {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

.CalendarDay__lastDayOfWeek {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}

/* Selected States */

.CalendarDay__selected_span {
    @apply bg-primary-fresh bg-opacity-30 text-grey-800;
}

.CalendarDay__selected_span:hover {
    @apply bg-opacity-30 bg-primary-fresh;
}
@media (hover: hover) and (pointer: fine) {
    .CalendarDay__selected_span:hover {
        @apply bg-opacity-50 bg-primary-fresh;
    }
}

.CalendarDay__selected:after {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: 22px;

    @apply mx-auto bg-primary-fresh aspect-1;
}

.CalendarDay__selected_start.CalendarDay__selected:has(+ .CalendarDay__selected_span):before,
.CalendarDay__selected_start.CalendarDay__selected:has(+ .CalendarDay__selected):before,
.CalendarDay__selected_start.CalendarDay__selected:has(+ .CalendarDay__hovered_span):before,
.CalendarDay__selected_end.CalendarDay__selected:before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    z-index: -2;

    @apply mx-auto bg-primary-fresh bg-opacity-30;
}

.CalendarDay__selected_start:before {
    right: 0;
    left: 50%;
}

.CalendarDay__selected_end:before {
    right: 50%;
    left: 0;
}

@media (hover: hover) and (pointer: fine) {
.CalendarDay__selected:after:hover {
    @apply bg-primary-dark;
}

.CalendarDay__selected:before:hover {
    @apply !bg-opacity-50 bg-primary-fresh;
}
}

/* Hover States */

@media (hover: hover) and (pointer: fine) {
    .CalendarDay__hovered_span {
        @apply bg-opacity-30 bg-primary-fresh text-grey-800;
    }

    .CalendarDay__hovered_span:hover {
        z-index: 1;
        position: relative;

        @apply text-white bg-transparent;
    }

    .CalendarDay__hovered_span:hover:after {
        content: "";
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-radius: 22px;

        @apply mx-auto bg-primary-300 aspect-1;
    }

    .CalendarDay__hovered_span:hover:before {
        content: "";
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 50%;
        z-index: -2;

        @apply mx-auto bg-primary-fresh bg-opacity-30;
    }
}

/* Disabled Dates */
.CalendarDay__blocked_calendar {
    @apply bg-grey-150 text-grey-650;
}

@media (hover: hover) and (pointer: fine) {
    .CalendarDay__blocked_calendar:hover {
        @apply bg-grey-200 text-grey-750;
    }
}

/* Ensure all blocked days are reset to not have rounded corners */
.CalendarDay__blocked_calendar {
    border-radius: 0;
}

/* Target the first blocked day and add left rounded corners */
.CalendarDay__blocked_calendar:first-of-type {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

/* Target the last blocked day and add right rounded corners */
.CalendarDay__blocked_calendar:last-of-type {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}

.CalendarMonth td:not(.CalendarDay__blocked_calendar)+.CalendarDay__blocked_calendar {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

.CalendarMonth .CalendarDay__blocked_calendar:has(+ td:not(.CalendarDay__blocked_calendar)) {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}