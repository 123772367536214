@import "react-day-picker/lib/style.css";

/* DayPicker Global */
.DayPickerInput {
    width: 100%;
    height: 100%;
}

.DayPicker-wrapper {
    padding-bottom: 15px;
}

.DayPicker-Month {
    height: min-content;
    table-layout: fixed;
}
.DayPicker-Caption {
    @apply !text-center;
}

/* DayPicker Public */
.DayPickerPublic .DayPickerInput input {
    @apply relative flex justify-between w-full p-20 cursor-pointer placeholder-grey-500 text-grey-800 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50 rounded-4;
}

@media screen and (max-width: 355px) {
    .DayPickerPublic .DayPickerInput-Overlay {
        position: relative;
    }
}

.DayPickerPublic.align-right .DayPickerInput-Overlay {
    right: 0px;
    left: initial;
}

.DayPickerPublic
    .DayPickerInput-OverlayWrapper
    .DayPickerInput-Overlay
    .DayPicker {
    color: coral;
    @apply w-full px-0 py-30 sm:px-40 sm:py-30;
}

.DayPickerPublic .DayPicker-NavButton--next {
    background-image: url("/assets/icons/chevron-down.svg");
    height: 30px;
    width: 30px;
    border: #535353 1px solid;
    transform: rotate(270deg);
    border-radius: 100%;
    background-size: 12px 12px;
    @apply top-0 my-auto right-20 text-primary;
}

.DayPickerPublic.DayPickerPublicPopover .DayPicker-NavButton--next {
    right: 0px;
}

.DayPickerPublic .DayPicker-NavButton--prev {
    background-image: url("/assets/icons/chevron-down.svg");
    height: 30px;
    width: 30px;
    transform: rotate(90deg);
    border: #535353 1px solid;
    border-radius: 100%;
    background-position: center;
    background-size: 12px 12px;
    @apply left-20 top-[0px] text-primary my-auto;
}

.DayPickerPublic.DayPickerPublicPopover .DayPicker-NavButton--prev {
    left: 0px;
}

.DayPickerPublic .DayPicker-Caption > div {
    font-size: 16px !important;
    margin-top: 5px;
    @apply text-grey-800;
}

.DayPickerPublic .DayPicker-Months {
    flex-wrap: nowrap;
    width: 100%;
    gap: 60px;
}
.DayPickerPublic .DayPicker-Month {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

@media only screen and (max-width: 1500px) {
    /* This query is here as normally the Day Picker has 2 months displayed. This removes the second */
    .DayPickerPublic:not(.DayPickerPublicPopover) .DayPicker-Month:not(:first-child) {
        display: none;
    }
}

.DayPickerPublic .DayPicker-Day {
    height: 43px !important;
    max-height: 43px !important;
    min-width: 43px !important;
    @apply border border-[#EDE9E6]  rounded-[0px] p-0 font-sans font-normal text-14 text-grey-800;
}

.DayPickerPublic.DayPickerPublicPopover .DayPicker-Day {
    height: 49px !important;
    max-height: 49px !important;
    min-width: 49px !important;
}

@media screen and (max-width: 350px) {
    .DayPickerPublic .DayPicker-Day {
        min-height: 20px !important;
        min-width: 20px !important;
    }
}

.DayPickerPublic .DayPicker-Day:after {
    padding-bottom: 100% !important;
}

.DayPickerPublic .DayPicker-Day--outside {
    @apply text-transparent;
}

.DayPickerPublic .DayPicker-Day--disabled {
    text-decoration: line-through;
    @apply pointer-events-none text-grey-500;
}

/* Public Selected Days */
.DayPickerPublic.DayPicker-inline
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background: inherit !important;
}
.DayPickerPublic:not(.DayPicker-inline)
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside) {
    background: #a28d50 !important;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerPublic
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #a99058;
    opacity: 0.1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerPublic .DayPicker-Day--selected_end:not(.DayPicker-Day--outside) {
    background: #a28d50 !important;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerPublic
    .DayPicker-Day--selected_end:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #a99058;
    opacity: 0.1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerPublic
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside) {
    @apply !text-grey-850;
    position: relative;
    background: inherit;
    z-index: 1;
}

.DayPickerPublic
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside)::before {
    background: #f7f7f7 !important;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerPublic
    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected_range) {
    background: #a28d50 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

/* DayPicker Admin */

.DayPickerAdmin .DayPicker {
    @apply transition-all;
}

.DayPickerAdmin .DayPickerInput input {
    background-color: transparent !important;
    @apply rounded-8 px-24 py-[13px] text-16 w-full ring-input-border ring-2 border-primary ring-opacity-60 focus:outline-none focus:ring-primary focus:ring-opacity-20;
}

.DayPickerAdmin .DayPickerInput input:focus {
    @apply rounded-6 border-primary;
}

.DayPickerAdmin .DayPickerInput-Overlay {
    /* border-radius: 20px !important; */
    position: absolute;
    height: min-content;
    @apply mt-10 rounded-10 border-primary border-3 border-opacity-6 text-grey-800;
}

.DayPickerAdminOverlay .DayPickerInput-Overlay {
    @apply z-10;
}

.DayPickerAdmin .DayPicker-wrapper {
    padding-bottom: 0px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
}

.DayPickerAdmin .DayPicker-Caption {
    @apply text-grey-850 text-[19px];
}

.DayPickerAdmin .DayPicker-Weekday {
    @apply font-bold text-primary text-15 gap-15;
}

.DayPickerAdmin .DayPicker-Day {
    height: 40px !important;
    max-height: 40px;
    min-width: 40px !important;
    font-size: 13px !important;
    position: relative;
    background: inherit !important;
    @apply rounded-[0px] p-0 !font-sans !font-semibold  !text-grey-850 gap-15;
}
.DayPickerAdmin
    .DayPicker-Day:hover:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    background: rgba(169, 144, 88, 0.1) !important;
    border-radius: 100%;
}

.DayPickerAdmin .DayPicker-NavButton--prev {
    background-image: url("/assets/images/navbar-left.png");
    height: 12px;
    background-size: contain;
    @apply my-auto left-20 top-26 text-primary;
}

.DayPickerAdmin .DayPicker-NavButton--next {
    background-image: url("/assets/images/navbar-right.png");
    height: 12px;
    background-size: contain;
    @apply my-auto right-20 top-26 text-primary;
}

.DayPickerAdmin .DayPicker-Day--disabled {
    text-decoration: line-through;
    @apply !opacity-30;
}

/* .DayPickerAdmin .DayPicker-Day--today {
  @apply !bg-primary !text-white;
} */

/* Admin Selected Days */

.DayPickerAdmin
    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected_range) {
    border-radius: 0;
    background: #F7F4EF !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: #474646 !important;
}

.DayPickerAdmin
    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected_range).DayPicker-Day--start {
    background: #a28d50 !important;
    border-top-left-radius: 100% !important;
    border-bottom-left-radius: 100% !important;
    color: white !important;
}

.DayPickerAdmin
    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected_range).DayPicker-Day--end {
    background: #a28d50 !important;
    border-top-right-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
    color: white !important;
}

.DayPickerAdmin .DayPicker-Day--selected_start:not(.DayPicker-Day--outside) {
    background: #a28d50 !important;
    opacity: 1 !important;
    border-radius: 100%;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerAdmin
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #a99058;
    opacity: 0.1;
    border-radius: 100% 0px 0px 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerAdmin .DayPicker-Day--selected_end:not(.DayPicker-Day--outside) {
    background: #a28d50 !important;
    opacity: 1 !important;
    border-radius: 100%;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerAdmin
    .DayPicker-Day--selected_end:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #a99058;
    opacity: 0.1;
    border-radius: 0px 100% 100% 0px;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerAdmin
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside) {
    @apply !text-grey-850;
    position: relative;
    background: inherit;
}
.DayPickerAdmin
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside)::before {
    background: #a99058 !important;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
    opacity: 0.1;
}

/* DayPicker Public */
.DayPickerHome .DayPickerInput input {
    @apply relative flex justify-between w-full px-20 border-none cursor-pointer py-15 h-60 focus:outline-none bg-grey-100 placeholder-grey-500 text-grey-800 focus:ring focus:ring-primary-fresh focus:ring-opacity-30 rounded-16;
}

@media screen and (max-width: 355px) {
    .DayPickerHome .DayPickerInput-Overlay {
        position: relative;
    }
}

.DayPickerHome.align-right .DayPickerInput-Overlay {
    right: 0px;
    left: initial;
}

.DayPickerHome
    .DayPickerInput-OverlayWrapper
    .DayPickerInput-Overlay
    .DayPicker {
    color: coral;
    @apply w-full px-0 py-30 sm:px-40 sm:py-30;
}

.DayPickerHome .DayPicker-NavButton--next {
    background-image: url("/assets/icons/chevron-down.svg");
    height: 30px;
    width: 30px;
    border: #535353 1px solid;
    transform: rotate(270deg);
    border-radius: 100%;
    background-size: 12px 12px;
    @apply top-0 my-auto right-20 text-primary;
}

.DayPickerHome.DayPickerHomePopover .DayPicker-NavButton--next {
    right: 0px;
}

.DayPickerHome .DayPicker-NavButton--prev {
    background-image: url("/assets/icons/chevron-down.svg");
    height: 30px;
    width: 30px;
    transform: rotate(90deg);
    border: #535353 1px solid;
    border-radius: 100%;
    background-position: center;
    background-size: 12px 12px;
    @apply left-20 top-[0px] text-primary my-auto;
}

.DayPickerHome.DayPickerHomePopover .DayPicker-NavButton--prev {
    left: 0px;
}

.DayPickerHome .DayPicker-Caption > div {
    font-size: 16px !important;
    margin-top: 5px;
    @apply text-grey-800;
}

.DayPickerHome .DayPicker-Months {
    flex-wrap: nowrap;
    width: 100%;
    gap: 60px;
}
.DayPickerHome .DayPicker-Month {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

@media only screen and (max-width: 1500px) {
    /* This query is here as normally the Day Picker has 2 months displayed. This removes the second */
    .DayPickerHome:not(.DayPickerHomePopover) .DayPicker-Month:not(:first-child) {
        display: none;
    }
}

.DayPickerHome .DayPicker-Day {
    height: 43px !important;
    max-height: 43px !important;
    min-width: 43px !important;
    @apply border border-[#EDE9E6]  rounded-[0px] p-0 font-sans font-normal text-14 text-grey-800;
}

.DayPickerHome.DayPickerHomePopover .DayPicker-Day {
    height: 49px !important;
    max-height: 49px !important;
    min-width: 49px !important;
}

@media screen and (max-width: 350px) {
    .DayPickerHome .DayPicker-Day {
        min-height: 20px !important;
        min-width: 20px !important;
    }
}

.DayPickerHome .DayPicker-Day:after {
    padding-bottom: 100% !important;
}

.DayPickerHome .DayPicker-Day--outside {
    @apply text-transparent;
}

.DayPickerHome .DayPicker-Day--disabled {
    text-decoration: line-through;
    @apply pointer-events-none text-grey-500;
}

/* Public Selected Days */
.DayPickerHome.DayPicker-inline
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background: inherit !important;
}
.DayPickerHome:not(.DayPicker-inline)
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside) {
    background: #a28d50 !important;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerHome
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #a99058;
    opacity: 0.1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerHome .DayPicker-Day--selected_end:not(.DayPicker-Day--outside) {
    background: #a28d50 !important;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerHome
    .DayPicker-Day--selected_end:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #a99058;
    opacity: 0.1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerHome
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside) {
    @apply !text-grey-850;
    position: relative;
    background: inherit;
    z-index: 1;
}

.DayPickerHome
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside)::before {
    background: #f7f7f7 !important;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerHome
    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected_range) {
    background: #a28d50 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}