.message-editor {
    @apply flex flex-col w-full h-full;
}

.message-editor .ql-container {
    @apply !border-none !flex-grow;
}

.message-editor .ql-toolbar {
    @apply !border-none !border-t !border-grey-100 !gap-x-5 !flex !flex-wrap !items-center !gap-y-10 !p-0 !flex-shrink-0;
}

.message-editor .quill {
    @apply flex flex-col flex-grow;
}

.message-editor .ql-editor {
    @apply font-sans;
}

.admin-reply-editor .message-editor {
  @apply lg:!max-h-full;
}

.admin-reply-editor .message-editor .ql-editor {
  @apply lg:!max-h-[calc(100vh-280px)] font-sans-dashboard;
}

.message-editor .ql-formats {
    @apply !mr-0;
}

.message-editor .ql-toolbar button {
    @apply !text-grey-800;
}

.message-editor .ql-stroke {
    @apply !stroke-grey-800;
}

.message-editor .ql-fill {
    @apply !fill-grey-800;
}

.message-editor .ql-active .ql-stroke {
    @apply !stroke-primary-fresh;
}

.message-editor .ql-active .ql-fill {
    @apply !fill-primary-fresh;
}

.message-editor .ql-attachment {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M5.602 19.8C4.309 19.8 3.098 19.245 2.224 18.36C0.529004 16.644 0.0570042 13.649 2.433 11.244L12.181 1.374C13.169 0.374003 14.426 -0.0129969 15.629 0.314003C16.812 0.634003 17.78 1.615 18.097 2.812C18.419 4.032 18.038 5.305 17.051 6.305L7.728 15.745C7.196 16.284 6.594 16.603 5.99 16.667C5.391 16.731 4.82 16.537 4.42 16.132C3.696 15.396 3.592 14.015 4.798 12.795L11.346 6.165C11.615 5.893 12.051 5.893 12.32 6.165C12.589 6.437 12.589 6.879 12.32 7.151L5.771 13.782C5.205 14.354 5.153 14.901 5.394 15.146C5.5 15.252 5.66 15.301 5.845 15.28C6.128 15.251 6.451 15.064 6.754 14.759L16.077 5.32C16.717 4.672 16.962 3.91 16.767 3.175C16.6695 2.81558 16.4809 2.48743 16.2193 2.22235C15.9577 1.95727 15.6321 1.76426 15.274 1.662C14.548 1.465 13.794 1.714 13.154 2.362L3.406 12.232C1.59 14.071 2.025 16.188 3.197 17.375C4.37 18.562 6.459 19.004 8.276 17.163L18.024 7.293C18.0876 7.22836 18.1634 7.17702 18.247 7.14197C18.3306 7.10693 18.4203 7.08888 18.511 7.08888C18.6017 7.08888 18.6914 7.10693 18.775 7.14197C18.8586 7.17702 18.9344 7.22836 18.998 7.293C19.1274 7.42466 19.1999 7.60189 19.1999 7.7865C19.1999 7.97112 19.1274 8.14834 18.998 8.28L9.25 18.15C8.101 19.312 6.814 19.8 5.602 19.8Z' fill='%23535353'/%3E%3C/svg%3E")
        no-repeat center !important;
}

.toolbar-wrapper {
    @apply pt-25;
}

.message-editor .ql-emoji {
    @apply !ml-auto !h-20 !w-20 !p-0 mr-15;
}

.message-editor .ap {
    text-indent: 0px !important;
}