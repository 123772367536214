@import "swiper/css/bundle";

.swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    /* // CSS Grid/Flexbox bug size workaround */
    /* // @see https://github.com/kenwheeler/slick/issues/982 */
    min-height: 0;
    min-width: 0;
    overflow-x: hidden;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
}

.swiper-horizontal>.swiper-pagination-bullets {
    top: initial !important;
}

.swiper-slide {
    width: auto;
    flex-shrink: 0 !important;
    display: block;
    height: 100%;
    max-height: 100%;
}

.swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
}

.carousel-bullet {
    margin-left: 4px;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background: #c4c4c4;
    cursor: pointer;
}

.carousel-bullet-active {
    background: #1a1a1a;
}

.swiper-pagination-bullet-active {
    background: #1a1a1a !important;
}

.carousel-bullets-dynamic .swiper-pagination-bullet-active {
    background: #a1a1a1 !important;
}

.carousel-bullets {
    width: 100% !important;
}

.carousel-bullets-dynamic {
    bottom: 30px !important;
    overflow: hidden;
}

@media screen(sm) {
    .carousel-bullets-dynamic {
        bottom: initial !important;
        overflow: visible;
        width: 100% !important;
    }

    .carousel-bullets-dynamic .carousel-bullet {
        transform: scale(1) !important;
        left: 0 !important;
    }

    .carousel-bullets-dynamic .swiper-pagination-bullet-active {
        background: #1a1a1a !important;
    }
}

.carousel-bullets-dynamic .carousel-bullet {
    transform: scale(0.33);
    position: relative;
}


.carousel-clickable-destinations.swiper-pagination-bullets {
    @apply !top-0 w-full h-max;
}

.carousel-clickable-featured-properties.swiper-pagination-bullets {
    @apply !top-0 w-full h-max;
}

.carousel-clickable-magazine.swiper-pagination-bullets {
    @apply !top-[285px] h-max sm:hidden carousel-bullets;
}

.carousel-clickable-membership.swiper-pagination-bullets {
    @apply !top-[435px] h-max sm:hidden carousel-bullets;
}

.carousel-bullets-dynamic .carousel-bullet-active {
    transform: scale(1);
}

.carousel-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
}

.carousel-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
}

.carousel-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
}

.carousel-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
}

.carousel-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
}

.swiper-vertical>.swiper-pagination-bullets.carousel-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
}

.swiper-vertical>.swiper-pagination-bullets.carousel-bullets-dynamic .carousel-bullet {
    display: inline-block;
    transition: 200ms transform, 200ms top;
}

.swiper-horizontal>.swiper-pagination-bullets.carousel-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.swiper-horizontal>.swiper-pagination-bullets.carousel-bullets-dynamic .carousel-bullet {
    transition: 200ms transform, 200ms left;
}

.swiper-horizontal.swiper-rtl>.carousel-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
}

.swiper-hide-tap-effect {
    -webkit-tap-highlight-color: transparent !important;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev-static,
.swiper-button-next-static {
    display: flex !important;
    visibility: hidden;
    color: transparent !important;
    width: 50px !important;
    height: 50px !important;
    background-color: #ffffff;
    border-radius: 9999px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
}

.swiper-button-prev {
    left: 10%;
    @apply top-[calc(50%+1.5rem)];
}

.swiper-button-next {
    right: 10%;
    @apply top-[calc(50%+1.5rem)];
}

@media screen(md) {
    .swiper-button-prev {
        left: 14%;
    }

    .swiper-button-next {
        right: 14%;
    }
}

@media screen(sm) {

    .swiper-button-prev,
    .swiper-button-next {
        visibility: visible;
    }
}

@media screen(xl) {
    .swiper-button-prev {
        left: 1/6;
    }

    .swiper-button-next {
        right: 1/6;
    }
}

.swiper-button-prev:after,
.swiper-button-next:after {
    --swiper-navigation-size: 50px;

    background: url("/assets/icons/carousel-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.swiper-button-next-static:after,
.swiper-button-prev-static:after {
    content: "";
    display: block;
    position: relative !important;
    background: url("/assets/icons/carousel-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.swiper-button-next-static.swiper-button-disabled,
.swiper-button-prev-static.swiper-button-disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
}

@media screen and (max-width: 640px) {

    .swiper-button-prev-static,
    .swiper-button-next-static,
    .swiper-button-prev-static:after,
    .swiper-button-next-static:after {
        height: 40px;
        width: 40px;
        display: none;
    }
}

.swiper-button-prev:after,
.swiper-button-prev-static:after {
    transform: rotate(180deg);
}

.swiper-button-property-images-prev,
.swiper-button-property-images-next {
    color: transparent !important;
    width: 15px !important;
    height: 27.89px !important;
    z-index: 10;
    background: url("/assets/icons/carousel-chevron.svg");
    background-repeat: no-repeat;
}

@media screen and (max-width: 640px) {

    .swiper-button-property-images-prev,
    .swiper-button-property-images-next {
        height: 24px !important;
        width: 10px !important;
        color: transparent !important;
    }
}

.swiper-button-property-images-prev {
    left: 6px;
}

.swiper-button-property-images-next {
    right: 6px;
    transform: rotate(180deg);
}

.swiper-button-admin-property-images-prev,
.swiper-button-admin-property-images-next {
    width: 29.25px !important;
    height: 29.25px !important;
    z-index: 10;
    background: url("/assets/icons/chevron-right-transparent.svg");
    background-repeat: no-repeat;
    opacity: 90%;
}

.swiper-button-admin-property-images-prev {
    left: 4px;
    transform: rotate(180deg);
}

.swiper-button-admin-property-images-next {
    right: 4px;
}