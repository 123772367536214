.day-content {
    @apply relative !important;
}

.day-content.day-event-airbnb {
    --event-background: var(--color-soft-red);
}
.day-content.day-event-other {
    --event-background: var(--color-soft-blue);
}
.day-content.day-event-file {
    --event-background: var(--color-green);
}
.day-content.day-event-ama_selections_no_client {
    --event-background: var(--color-grey);
}
.day-content.day-event-ama_selections {
    --event-background: var(--color-soft-yellow);
}

.day-content.day-event-airbnb-end {
    --event-background-end: var(--color-soft-red);
}
.day-content.day-event-airbnb-start {
    --event-background-start: var(--color-soft-red);
}

.day-content.day-event-other-end {
    --event-background-end: var(--color-soft-blue);
}
.day-content.day-event-other-start {
    --event-background-start: var(--color-soft-blue);
}

.day-content.day-event-file-end {
    --event-background-end: var(--color-green);
}
.day-content.day-event-file-start {
    --event-background-start: var(--color-green);
}

.day-content.day-event-ama_selections_no_client-start {
    --event-background-start: var(--color-grey);
}
.day-content.day-event-ama_selections_no_client-end {
    --event-background-end: var(--color-grey);
}

.day-content.day-event-ama_selections-start {
    --event-background-start: var(--color-soft-yellow);
}
.day-content.day-event-ama_selections-end {
    --event-background-end: var(--color-soft-yellow);
}

.round-right {
    @apply rounded-tr-full rounded-br-full text-white !important;
    background-color: #FFC36D !important;
}

.round-left {
    @apply rounded-tl-full rounded-bl-full text-white !important;
    background-color: #FFC36D !important;
}

.range .day-content {
    background-color: #FFF2CC !important;
}

.day-content:hover {
    background-color: #FFC36D !important;
    @apply rounded-full !important;
}

.range-end .day-content {
    @apply rounded-tr-full rounded-br-full rounded-tl-none rounded-bl-none text-white !important;
    background-color: #FFC36D !important;
}

.range-start .day-content {
    @apply rounded-tl-full rounded-bl-full rounded-tr-none rounded-br-none text-white !important;
    background-color: #FFC36D !important;
}

.day-content.day-event-end:before, .day-content.day-event-start:after {
    content: "";
    @apply w-1/2 h-full absolute top-0 opacity-50;
}

.day-content.day-event-end:before {
    @apply rounded-r-full left-0;
    background-color: var(--event-background-end);
}

.day-content.day-event-start:after {
    @apply rounded-l-full right-0 opacity-50;
    background-color: var(--event-background-start);
}

.day-content.day-event::before {
    @apply absolute inset-0 opacity-50;
    z-index: -10;
    content: "";
    background-color: var(--event-background) !important;
}

.calendar {
    @apply w-full  !important;
}

.rates-calendar {
    @apply flex justify-center;
}

.calendar-overflow {
    @apply overflow-visible !important;
}

.months-container {
    @apply inline-flex flex-wrap justify-center gap-10 !important;
}

.rates-calendar .months-container {
    @apply justify-center !important;
}

.rates-calendar .months-container .month-container {
    @apply basis-1/5 !important;
}

.month-container {
    @apply border border-grey-200 rounded-10 p-10 xs:p-15 sm:max-w-[300px] !important;
}

.month-title {
    @apply text-14 font-semibold pb-8 text-grey-800 !important;
}

.month {
    @apply flex-shrink w-full;
}

.day-header {
    @apply text-12 text-primary !important;
}

.calendar table.month td.day .day-content {
    @apply font-medium text-12 text-grey-650 px-10 py-[6px] my-2 !important;
}

.calendar .months-container .month-container {
    @apply float-none text-center h-auto !important;
}