@import "swiper/css/bundle";

.swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    /* // CSS Grid/Flexbox bug size workaround */
    /* // @see https://github.com/kenwheeler/slick/issues/982 */
    min-height: 0;
    min-width: 0;
    overflow-x: hidden;
}

.swiper-horizontal>.onboarding-swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
}

.swiper-horizontal>.onboarding-swiper-pagination-bullets {
    top: initial !important;
}

.onboarding-carousel-bullet {
    margin-left: 4px;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background: #D9DBE9;
    cursor: pointer;
}

.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active {
    background: white !important;
    width: 16px;
    border-radius: 4px;
}

.onboarding-carousel-bullets-dynamic {
    bottom: 30px !important;
    overflow: hidden;
}

@media screen(sm) {
  .onboarding-carousel-bullets-dynamic {
      bottom: initial !important;
      overflow: visible;
      width: 100% !important;
  }

  .onboarding-carousel-bullets-dynamic .onboarding-carousel-bullet {
      transform: scale(1) !important;
      left: 0 !important;
  }

  .onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active {
      background: white !important;
  }
}

.onboarding-carousel-bullets-dynamic .onboarding-carousel-bullet {
    transform: scale(0.33);
    position: relative;
}

.onboarding-carousel-bullets-dynamic .carousel-bullet-active {
    transform: scale(1);
}

.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
    background: white !important;
}

.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(1);
}

.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
}

.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(1);
}

.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
}

.swiper-vertical>.onboarding-swiper-pagination-bullets.onboarding-carousel-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
}

.swiper-vertical>.onboarding-swiper-pagination-bullets.onboarding-carousel-bullets-dynamic .onboarding-carousel-bullet {
    display: inline-block;
    transition: 200ms transform, 200ms top;
}

.swiper-horizontal>.onboarding-swiper-pagination-bullets.onboarding-carousel-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.swiper-horizontal>.onboarding-swiper-pagination-bullets.onboarding-carousel-bullets-dynamic .onboarding-carousel-bullet {
    transition: 200ms transform, 200ms left;
}

.swiper-horizontal.swiper-rtl>.onboarding-carousel-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
}

.carousel-clickable-onboarding.swiper-pagination-bullets {
  @apply md:!top-0 onboarding-swiper-pagination-bullets !bottom-[256px] onboarding-carousel-bullets-dynamic sm:!invisible z-[2];
}

.onboarding-carousel-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
  @apply !bg-white;
}